$(document).ready(function(){

    materialDocumentReady();
    if($("#contactUsMap").length) {
        materialKitDemo.initContactUsMap();
    }
    //if(window.validate_registration_form){
		//validate_registration_form();
		//add_js_input('#registration_form');
	//}

});

$(window).load(function() {

    if ($(".swerk-notifcatie")[0]){
        setTimeout(function() { $('.swerk-notifcatie').css('top','0px') }, 1000);
        setTimeout(function() { $('.swerk-notifcatie').css('top','-100px') }, 8000);
    }


});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
function add_js_input(form_id){
    $('<input>').attr({
        type: 'hidden',
        id: 'captcha',
        name: 'captcha',
        value: 'honey'})
    .appendTo(form_id);
}